import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';


const img0 = require('../../images/services1.jpg');
const img1 = require('../../images/services2.jpg');
const img2 = require('../../images/services3.jpg');
const img3 = require('../../images/services4.jpg');

//const lorem = 'Lorem Ipsum dolor dummmy text';

const data = [
    { img: img0, label: 'Installation', text: `Enjoy your purchase without worrying? Do It For Me ensures an optimal installation.`, href: 'about/#service-installation' },
    { img: img1, label: 'Maintenance', text: `Enjoy your product for a longer time? Do It For Me offers you the necessary support.`, href: 'about/#service-maintenance' },
    { img: img2, label: 'Repair', text: `Your robotic mower is broken? Do It For Me repairs it with original spare parts.`, href: 'about/#service-repair' },
    { img: img3, label: 'Webshop', text: `Looking for accessories for your robotic mower? Do It For Me delivers them at your doorstep!`, href: 'about/#service-webshop' },
]

class Component extends React.Component {

    render() {

        const { country, language, t } = this.props;

        return (
            <div id="services" className="service-list">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h1>{t('Services')}</h1>
                        </div>
                    </div>
                    <div className="row">
                        {data.map((item) => (
                            <div key={item.text} className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-3">
                                <div className="servBox">
                                    <Link to={`/${language.toLowerCase()}/${country.toLowerCase()}/${item.href}`} >
                                        <div className="serviceImage" style={{ backgroundImage: "url('" + item.img + "')" }}>
                                            <span className="hov-arrow-top"></span><span className="hov-arrow-btm"></span>
                                            <span className="hov-txt-sec">{t(item.text)}</span>
                                        </div>
                                        <div className="servTTL">{t(item.label)}</div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
};

Component.propTypes = {
    t: PropTypes.func
};

export default Component;
