import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/swiper.min.css'
import sc_1 from '../../images/Robotic_Mower.png';
import sc_2 from '../../images/Industrial_Doors.png';
import sc_3 from '../../images/kitchen.png';
// import sc_4 from '../../images/Home_Security.png';
import sc_4 from '../../images/Fire_Place.png';
import sc_5 from '../../images/Fitted_Wardrobes.png';

import Axios from 'axios';

import { Manager, Reference, Popper } from 'react-popper';
import { Link, navigate } from 'gatsby';

const internals = {
    services: {
        '1': 'New Installation',
        '2': 'Service Visit',
        '3': 'Wintercare at Home',
        '4': 'Wintercare at DIFM',
        '5': 'Winterstorage and wintercare at DIFM',
        '6': 'Winterstorage at DIFM'
    }
};



internals.serviceCategories = [
    { id: 'robotic mowers',   img: sc_1,  name: 'Robotic Mower',  active: true  },
    { id: 'industrial doors',     img: sc_2,  name: 'Industrial Doors',   active: true  },
    { id: 'kitchen',        img: sc_3,  name: 'Kitchen',        active: true },   
    { id: 'fire places',  img: sc_4,  name: 'Fire Place',  active: true },
    { id: 'fitted wardrobes',     img: sc_5,  name: 'Fitted Wardrobes',   active: true  },
    
];

class SearchBar extends React.Component {

    state = {
        searchModalOpen: false,
        searchResults: {
            category: [],
            brand: [],
            service: [],
            data: []
        },
        categoryBrands: [],
        brandModels: [],
        selectedBrand: '',
        selectedCategory: '',
        selectedService: '',
        channel: this.props.channel || 'default',
        country: this.props.country || 'be',
        flipped: {},
        countrySelection: false,
        serviceSelection: false,
        validate:''
    }

    constructor(props){
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }


    refSearchInput = React.createRef();

    startCountrySelection = (e) => {

        this.setState({ countrySelection: !this.state.countrySelection, searchResults: {
            category: [],
            brand: [],
            service: [],
            data: [],
        }, serviceSelection: false, flipped: {} });
    }
    
    updateBrandSwiper = (instance) => {

        this.brandSwiper = instance;
    }

    formatCurrency = (item, service, t) => {

        const currency = item.currency;
        const formatter = new Intl.NumberFormat(this.language, { style: 'currency', currency: currency || 'EUR' });
        service = service || item.services[0];
        const num = service ? service.price : item.price;

        if (num === 0) {
            if (service.isFree === '3') {
                return t('Already Paid');
            }
            return item.model.toLowerCase() === 'ander toestel' ? t('Quotation') : t('Free');   
        }
        return formatter.format(num);
    }

    handleSearchClick = (e) => {

        this.setState({ searchModalOpen: true });
    }

    search = async () => {

        const q = this.refSearchInput.current.value;
        let url = `/api/search?country=${this.props.country}&channel=${this.props.channel}&page=1&limit=200`;
        if (q) {
            url += `&q=${q}`;
        } 

        ['selectedBrand', 'selectedCategory', 'selectedService'].forEach((type) => {

            if (this.state[type]) {
                const param = type.replace('selected', '').toLowerCase();
                url += `&${param === 'service' ? 'service_type' : param}=${this.state[type]}`;
            }
        });

        const { data } = await Axios.get(url);
        return data;
    }

    flip = (e, item) => {


        const flipped = this.state.flipped;
        let status = true;
        if (flipped.hasOwnProperty(item)) {
            status = !flipped[item];
        }
        const newFlipped = Object.assign({}, this.state.flipped);
        newFlipped[item] = status;
        this.setState({ flipped: newFlipped });
    }

    handleSelectCategory = async (e, selectedCategory ) => { 

        this.setState({ selectedCategory }, async () => {

            const results = await this.search();
            results.category = this.state.searchResults.category;
            // results.brand = this.state.searchResults.brand;
            // results.service = this.state.searchResults.service;
            this.setState({ searchResults: results, flipped: {} }, () => {

                setTimeout(() => {

                    // this.brandSwiper.slideTo(0);
                }, 50);
            });
        });
    }

    handleServicesClick = (e, selectedCategory, active) => {
        if (active) {
            this.setState({
                searchModalOpen: true,
                selectedCategory
            });
        }

    }


    handleSubmit = async (e, item) => {
        e.preventDefault();

        const { t } = this.props;
           const selected_service = e.target.services.value;
           let price = item.price;
           let rid = item.rid;
           let isFree = false;
           if(selected_service){
            for (let i = 0; i < item.services.length; ++i) {
                const service = item.services[i];
                if (service.service_type === selected_service) {
                    price = service.price;
                    rid = service.rid;
                    isFree = service.isFree;
                    break;
                }
            }
            
            const { data } = await Axios({
                url: '/api/survey/init',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    country: this.props.country,
                    language:this.props.language === 'de_DE' ? 'de' : this.props.language,
                    category: item.category,
                    brand: t(item.brand),
                    model: t(item.model),
                    capacity: t(item.capacity),
                    rid: rid,
                    channel: t(item.channel),
                    service_type: selected_service,
                    isFree: isFree,
                    price: price,
                    currency: item.currency,
                    area: item.area
                })
            });
            
            const uid = data.uid;
            const channel_path  = `${(this.props.channel === 'default' ? '' : '/'+this.props.channel).toLowerCase()}`;
            navigate(`/${this.props.language.toLowerCase()}/${this.props.country}${channel_path}/quote/?uid=${uid}`, { uid });
            
           } else{
            this.setState({validate: item.sku});
            return false;
           }        
    }

    handleSelectBrand = async (e, selectedBrand, i) => {

        this.setState({ selectedBrand }, async () => {

            const results = await this.search();
            results.category = this.state.searchResults.category;
            results.brand = this.state.searchResults.brand;
            results.service = this.state.searchResults.service;
            this.setState({ searchResults: results, flipped: {} }, () => {

                setTimeout(() => {

                    // this.brandSwiper.slideTo(i);
                }, 50);
            });   
        })
    }

    handleSelectService = async (e, selectedService) => {

        this.setState({ selectedService }, async () => {

            const results = await this.search();
            results.category = this.state.searchResults.category;
            results.brand = this.state.searchResults.brand;
            results.service = this.state.searchResults.service;
            this.setState({ searchResults: results, flipped: {} });    
        })
    }

    handleSearchChange = async (e) => {

        const run = async () => {
            const results = await this.search();
            this.setState({ searchResults: results, flipped: {} }, () => {

                setTimeout(() => {

                    // this.brandSwiper.slideTo(0);
                }, 50);
            });
        }

        const q = this.refSearchInput.current.value;

        if (!q) {
            return this.setState({ searchResults: {
                category: [],
                brand: [],
                service: [],
                data: []
            }, selectedBrand: '' }, async () => {
                await run();
            });
        }
        await run();
    }
    
    componentDidMount(){
        document.addEventListener('mousedown',this.handleClickOutside);
        //if(this.props.channel !== 'default') {
        //    this.setState({ searchModalOpen: true });
        //}
    }
    
    componentWillUnmount(){
        document.removeEventListener('mousedown',this.handleClickOutside);
    }

    handleClickOutside(event) {

        if(event.target.id !== 'selectedCity' && event.target.className !== 'city-result' && event.target.className !== 'city-selection'){
            this.setState({ countrySelection: false });
        }
    }

    closeModal = () => {

        this.setState({
            searchModalOpen: false, 
            searchResults: {
                category: [],
                brand: [],
                service: [],
                data: []
            },
            selectedBrand: '',
            selectedCategory: '', 
            flipped: {}  
        });
    }

    renderTile(item, t){
        const partnerTiles = ['industrial doors', 'fitted wardrobes']; 
        // if(item.id === "industrial doors"){
        if(partnerTiles.includes(item.id)){
            return ([
                <li key={item.name} onClick={(e) => item.active && navigate(`${this.props.location.pathname}/partners?category=${item.id.replace(' ', '_')}`)}>
                    <img src={item.img} width="100" alt={t(item.name)} />
                    <div>{t(item.name)}</div>
                    <div className="coming-soon-text" style={{ display: `${item.active ? 'none': 'block'}`}}>Coming Soon</div>
                </li>
            ]);
        } else {
            return ([
                <li key={item.name} onClick={(e) => item.active && navigate(`${this.props.location.pathname}/search?category=${item.id.replace(' ', '_')}`)}>
                    <img src={item.img} width="100" alt={t(item.name)} />
                    <div>{t(item.name)}</div>
                    <div className="coming-soon-text" style={{ display: `${item.active ? 'none': 'block'}`}}>Coming Soon</div>
                </li>
            ]);
        }

    }
    

    render() {

        const self = this;
        const { countries, t } = this.props;
        const countryOpacity = this.state.countrySelection ? 1 : 0;
        const channel = this.props.channel;
        const channel_path  = `${(channel === 'default' ? '' : '/'+channel).toLowerCase()}`;

        return (
            <>
                <div id="searchbox" className="container-fluid searchBox">
                    <h1>{t('Service. Install. Repair')}</h1>
                    <h2>{t('Select or search for a service type and get a trained professional at your doorstep')}</h2>
                    <div className="searchRow">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-center d-b">
                                        <Manager>
                                            <Reference>
                                                {({ ref }) => (
                                                    <div id="selectedCity" className="selected-city d-flex align-items-center justify-content-between" ref={ref} onClick={self.startCountrySelection}>
                                                        <i className="la la-map-marker"></i>
                                                            <span className="current-location">{t(this.state.country)}</span>
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                )}
                                            </Reference>
                                            <Popper placement="bottom-start" >
                                                {({ ref, style, placement }) => (
                                                    <div className="city-selection" ref={ref} style={{...style, 
                                                        display: countryOpacity ? 'block' : 'none', 
                                                        pointerEvents: self.state.searchModalOpen ? 'none' : 'auto'
                                                    }} data-placement={placement}>
                                                        <div className="select-city city-result">
                                                        <i className="la la-map-marker"></i>
                                                                    <span>{t('Select Country')}</span>
                                                                    <i className="la la-angle-down"></i>
                                                                    </div>
                                                        {
                                                            countries.map((item) => (
                                                                <Link key={item.code} to={`/${self.props.language.toLowerCase()}/${item.code.toLowerCase()}${channel_path}`}>
                                                                    <div
                                                                        className="city-result" 
                                                                        key={item.code} 
                                                                    >
                                                                        {t(item.code)}
                                                                    </div>
                                                                </Link>
                                                            ))
                                                        }
                                                    </div>
                                                )}
                                            </Popper>
                                        </Manager>
                                        <div className={`searchBar`} onClick={this.handleSearchClick}>
                                            <div className="input-group" onClick={() => navigate(`${this.props.location.pathname}/search`)}>
                                                <div className="autocomplete">
                                                    {t('Search...')}
                                                </div>
                                                <button name="Search" className="btn btn-primary btn-search">
                                                    <i className="la la-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="services">
                        <ul>
                            {
                                internals.serviceCategories.map((item,index) => (
                                    this.renderTile(item, t)
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="clearfix"></div>
            </>
        )
    }
}

SearchBar.propTypes = {
    t: PropTypes.func
};

export default SearchBar;
