import React, { Component } from 'react';

import Page from '../page';
import SearchBar from '../searchBar';
import HowTo from '../howto';
import Services from '../services';
import Reviews from '../reviews';

export default class extends Component {

    render() {

        return (
            <Page {...this.props}>
                <SearchBar />
                <HowTo />
                <Services />
                <Reviews />
            </Page>
        );
    }
};
