import React, { Component } from 'react';

import './styles.scss';

export default class extends Component {

    state = {
        x: 0,
        translateX: 0,
        ready: 0,
        view: 'desktop',
        numItems: 3,
        itemWidth: 0
    }

    outer = React.createRef()

    move = (index) => {

        const num = React.Children.count(this.props.children);

        let x = this.state.x + index;

        if (x + index < 0) {
            x = 0;
        }
        else if (x + index > (num - this.state.numItems)) {
            x = num - this.state.numItems;
        }

        const translateX = x * this.state.itemWidth;

        this.setState({ translateX, x });
    }

    prev = () => {

        this.move(-1);
    }

    next = () => {

        this.move(1);
    }

    updateSize = () => {

        let view = 'desktop';
        if (window.matchMedia("(max-width: 768px)").matches) {
            view = 'mobile';
        }

        const containerWidth =  this.outer.current.offsetWidth;
        const numItems = view === 'desktop' ? (this.props.numDesktopItems || 3) : (this.props.numMobileItems || 1);
        const itemWidth = (containerWidth) / numItems;

        this.setState({ ready: 1, view, numItems, itemWidth }, () => { 
            this.move(0)
        });
    }

    componentWillReceiveProps() {

        this.updateSize();
    }

    componentDidMount() {

        this.updateSize();
        window.addEventListener("resize", this.updateSize);
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.updateSize);
    }

    render() {

        return (
            <div className="slider container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div
                        className="col-1 prev d-flex align-items-center justify-content-center"
                        onClick={this.prev}
                    >
                        <i className="la la-angle-left"></i>
                    </div>
                    <div 
                        className="slider-items-container col-9 col-lg-10" 
                        ref={this.outer}
                        >
                        <div
                            className="slider-items"
                            style={{
                                transform: `translateX(-${this.state.translateX}px)`,
                            }}
                        >
                            {
                                this.state.ready && React.Children.map(this.props.children, (child, index) => {

                                    return (
                                        <div key={index} className="slider-item d-flex flex-column align-items-center justify-content-between" style={{ width: `${100/this.state.numItems}%`}}>
                                            {child}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div
                        className="col-1 prev d-flex align-items-center justify-content-center"
                        onClick={this.next}
                    >
                        <i className="la la-angle-right"></i>
                    </div>
                </div>
            </div>
        )
    }
}
