import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Slider from '../slider';

const img0 = require('../../images/img0.png');
const img1 = require('../../images/img1.png');
const img2 = require('../../images/img2.png');
const img3 = require('../../images/img3.png');
const map_icon = require('../../images/map-marker-alt.png');

//const loc_img = require('../../images/locationIcon.jpg');

const data = [
    { name: 'Reviewer1', img: img0, location: 'City1', text: 'Review1' },
    { name: 'Reviewer2', img: img1, location: 'City2', text: 'Review2' },
    { name: 'Reviewer3', img: img2, location: 'City3', text: 'Review3' },
    { name: 'Reviewer4', img: img3, location: 'City4', text: 'Review4' }
]

class Component extends React.Component {

    state = {
        index: 0
    }

    render() {

        const { t } = this.props;
        return (
            <>
                <div className="reviews">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>{t('Our client reviews')}</h1>
                            </div>
                        </div>
                        <div className="row p-r">
                            <Slider>
                                {
                                    data.map((item) => (
                                        <div className="item" key={item.name}>
                                            <div className="slide-info-box">
                                                <div className="sib-inn sib-2">
                                                    <div className="reviewsHeading">
                                                        <img src={item.img} alt="" />
                                                        <h3> {t(item.name)} </h3>
                                                        <div className="reviewsHeadinglocationIcon">
                                                            <img src={map_icon} alt="" />
                                                            {t(item.location)}
                                                        </div>
                                                    </div>
                                                    <span>{t(item.text)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>

                        </div>
                    </div>
                </div>
                <div>
                    <div className="container home-book-ur-ser">
                        <div className="bookServiceNowDiv">
                            <span>
                                {t('CTA Title')}
                            </span>
                            <Link to={`${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                    {t('CTA Button')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

Component.propTypes = {
    t: PropTypes.func
};

export default Component;
