import React from 'react';
import PropTypes from 'prop-types';

import ico0 from '../../images/icon1.png';
import ico1 from '../../images/icon2.png';
import ico2 from '../../images/icon3.png';
import ico3 from '../../images/icon4.png';
import ico4 from '../../images/icon5.png';

//const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

const sections = [
    { img: ico0, name: 'Search Service', text:  'Search for the service, the brand and the model of your device in the search bar.' }, 
    { img: ico1, name: 'Schedule Service', text: 'Fill in your contact details and choose the moments of your choice'},     
    { img: ico2, name: 'Payment', text: 'Pay in complete security and immediately get your invoice' }, 
    { img: ico3, name: 'Appointment confirmation', text: 'A \'Do It For Me\' team members will contact you within 2 working days in order to confirm your appointment.' }, 
    { img: ico4 , name: 'Service Delivery', text: 'An authorized installer wil do the installation and maintenance at your home or in our workshop! You enjoy your new device or product to the fullest.'}
];

class How extends React.Component {

    state = {
        selected: 0,
        max: 4
    }
    interval = null;

    componentDidMount() {
        
        this.interval = setInterval(() => {
            const selected = this.state.selected === this.state.max ? 0 : (this.state.selected + 1);
            this.setState({ selected })
        }, 4000)
    }

    next = () => {

        let selected = this.state.selected;
        selected =  selected === this.state.max ? 0 : selected + 1;
        this.setState({ selected });
    }

    prev = () => {

        let selected = this.state.selected;
        selected =  selected === 0 ? this.state.max : selected - 1;
        this.setState({ selected });
    }

    handleClick = (e, i) => {

        this.setState({ selected: i });
        clearInterval(this.interval);
    }

    componentWillUnmount() {

        if (this.interval) {
            clearInterval(this.interval);
        }
    }


    render() {

        const { t } = this.props;

        return(
            <div className="howTo">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>{t('How does it work?')}</h1>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                            <div className="col-1 howToNav" onClick={this.prev}>
                                <i className="la la-angle-left"></i>
                            </div>
                            {
                                [0, 1, 2, 3, 4].map((item) => (
                                    <div
                                        className={'col-lg-2 col-8 t-carousel-item' + (this.state.selected === item ? ' selected' : '')}
                                        key={item}
                                        onMouseOver={(e) => this.handleClick(e, item)}
                                        onClick={(e) => this.handleClick(e, item)}
                                    >
                                        <div className="num">{item + 1}</div>
                                        <div className="icon"><img alt={t(sections[item].name)} src={sections[item].img} /></div>
                                        <div className="name">{t(sections[item].name)}</div>
                                    </div>
                                ))
                            }
                            <div className="col-1 howToNav" onClick={this.next}>
                                <i className="la la-angle-right"></i>
                            </div>

                    </div>
                    <div className="row">
                        <div className="m-p-5 col-1"></div>
                        <div className="col-12 m-p-0">
                            <div className="service-description">
                                {t(sections[this.state.selected].text)}
                            </div>
                        </div>
                        <div className="m-p-5 col-1"></div>
                    </div>

                </div>
            </div>
        )
    }
}

How.propTypes = {
    t: PropTypes.func
}

export default How;
